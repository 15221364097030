import React, { useEffect, useState } from 'react'
import get from 'lodash/get'
import { TweenMax, TimelineMax } from 'gsap'
import raf from 'raf'
import Signals from '../core/Signals'

import {
  WebGLRenderer,
  Scene,
  PerspectiveCamera,
  PointLight,
  Mesh,
  Group,
  BoxGeometry,
  TextureLoader,
  Raycaster,
  Vector2,
} from 'three'

import TextSprite from '@seregpie/three.text-sprite'

import RefractionMaterial from '../libs/MirrorMaterial/MirrorMaterial'
import PlaneWave from '../objects/PlaneWave'
import { preloader } from '../loader/index'
import { TextureResolver } from '../loader/resolvers/TextureResolver'
import { ImageResolver } from '../loader/resolvers/ImageResolver'
import { GLTFResolver } from '../loader/resolvers/GLTFResolver'

import {
  EffectComposer,
  SMAAEffect,
  RenderPass,
  EffectPass,
  SMAAPreset,
  EdgeDetectionMode,
} from 'postprocessing'

import PreloaderScene from '../components/preloader'
import Header from '../components/header'
import Project from '../components/project'
import '../assets/styles/app.scss'

import {
  AllModels,
  LightPosition,
  CameraPosition,
  noise,
} from '../settings/index'

function MainScene(props) {
  const [selectedProject, setSelectedProject] = useState(0)

  useEffect(() => {
    let ReadingProject = false

    const CubesProject = []

    /* Custom settings */
    const SETTINGS = {
      useComposer: true,
    }
    let composer

    let delta = 0

    let deltaRotation = 0

    // Mouse

    let mouse = { x: 0, y: 0 }

    /* Init renderer and canvas */
    const container = document.body.querySelector('#application')

    const renderer = new WebGLRenderer()
    container.appendChild(renderer.domElement)

    /* Main scene and camera */
    const scene = new Scene()
    const camera = new PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    )
    camera.position.y = CameraPosition.y
    camera.position.x = CameraPosition.x
    camera.position.z = CameraPosition.z

    /* Lights */

    const backLight = new PointLight(0xffffff, 1.7)

    backLight.position.set(LightPosition.x, LightPosition.y, LightPosition.z)

    //Set up shadow properties for the light

    scene.add(backLight)

    function createCube(sx, sy, sz, image) {
      // var material = new MeshLambertMaterial({ map: texture, opacity: 0.5 });
      const refractionMaterial = new RefractionMaterial({
        envMap: image,
        resolution: [
          window.innerWidth * Math.min(devicePixelRatio, 2 || 1),
          window.innerHeight * Math.min(devicePixelRatio, 2 || 1),
        ],
      })

      var cube = new Mesh(new BoxGeometry(sx, sy, sz), refractionMaterial)
      return cube
    }

    /* Actual content of the scene */
    const MeshPlane = PlaneWave()
    MeshPlane.receiveShadow = true
    MeshPlane.castShadow = true
    scene.add(MeshPlane)

    // end controls

    const projects = get(props, 'data.allContentfulBlogPost.edges')

    const projectsModels = new Group()
    projectsModels.name = 'Les cubes'

    projects.map((node, index) => {
      const image = get(node, 'node.heroImage.fluid.src')
      const textureload = new TextureLoader().load(image)
      const cubeMesh = createCube(
        AllModels.cube.size.x,
        AllModels.cube.size.y,
        AllModels.cube.size.z,
        textureload
      )

      cubeMesh.name = `${index}`
      cubeMesh['enable'] = false

      const group = new Group()
      group.name = 'Rotate cube'
      group.rotation.y = AllModels.groups.rotation.y
      group.rotation.z = AllModels.groups.rotation.z
      group.position.z = AllModels.groups.position.z

      const cube = new Group()

      CubesProject.push(cubeMesh.name)

      group.add(cubeMesh)
      cube.add(group)
      cube.name = 'Cube Margin'
      cube.position.x += index * 0.5

      const textMesh = new TextSprite({
        text: node.node.title,
        fontFamily: 'Lato, sans-serif',
        fontSize: 0.02,
        fillColor: '#3F3F49',
        fillStyle: '#3F3F49',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: 'normal',
        lineGap: 0.15,
        padding: 0.25,
        strokeStyle: '#3F3F49',
        strokeWidth: 0,
      })

      textMesh.scale.x = 0.2
      textMesh.scale.y = 0.2
      textMesh.scale.z = 0.2

      textMesh.name = 'project Title'
      textMesh.position.z = 10.1
      textMesh.position.x = -0.05

      cube.add(textMesh)

      projectsModels.add(cube)
    })

    const clonedInstance = projectsModels.children[0].children[0].clone(true)

    scene.add(projectsModels)

    /* Preloader */
    preloader.init(
      new ImageResolver(),
      new GLTFResolver(),
      new TextureResolver()
    )

    preloader
      .load([
        {
          id: 'searchImage',
          type: 'image',
          url: SMAAEffect.searchImageDataURL,
        },
        {
          id: 'areaImage',
          type: 'image',
          url: SMAAEffect.areaImageDataURL,
        },
      ])
      .then(() => {
        initPostProcessing()
        _onResize()
        animate()
      })

    /* -------------------------------------------------------------------------------- */
    function initPostProcessing() {
      composer = new EffectComposer(renderer)
      const smaaEffect = new SMAAEffect(
        preloader.get('searchImage'),
        preloader.get('areaImage'),
        SMAAPreset.LOW,
        EdgeDetectionMode.LUMA
      )

      const effectPass = new EffectPass(camera, smaaEffect)
      const renderPass = new RenderPass(scene, camera)
      composer.addPass(renderPass)
      composer.addPass(effectPass)
      effectPass.renderToScreen = true
    }

    /* Carroussel */

    Signals.onProjectAnimationClose.add(_CloseProject)

    let touch_navigation = false
    let delta_navigation = 0

    let previousTouchPosition
    let currentTouchPosition

    let index = 0

    let baseTargetX = 0
    let baseX = 0
    let easing = 0.1

    let itemWidth = 0.5

    let openProject

    function _previous() {
      index = Math.min(index + 1, CubesProject.length - 1)
    }

    function _next() {
      index = Math.max(index - 1, 0)
    }

    function _updateIndex() {
      if (delta_navigation > 75 && index > 0) {
        _next()
        delta_navigation = 0
        previousTouchPosition = currentTouchPosition
      } else if (delta_navigation < -75) {
        _previous()
        delta_navigation = 0
        previousTouchPosition = currentTouchPosition
      }
    }

    function _updateItems() {
      _updateIndex()

      baseTargetX = (delta_navigation / 5000) * -1.5 + index * itemWidth

      baseX += (baseTargetX - baseX) * easing
    }

    function _CloseProject() {
      if (get(openProject, 'parent')) {
        const projectCurrent = openProject.parent
        const projectCurrentChildren = openProject
        const projectNew = clonedInstance
        const projectNewChildren = clonedInstance.children[0]

        const tl = new TimelineMax({
          delay: 1,
          onComplete: function() {
            projectCurrentChildren['enable'] = false
          },
        })

        TweenMax.fromTo(
          projectCurrentChildren.rotation,
          2.8,
          { x: openProject.rotation.x },
          { x: Math.PI / 2 + deltaRotation / 30, ease: 'circ.out' }
        )

        TweenMax.fromTo(
          projectCurrent.rotation,
          2.8,
          {
            x: projectCurrent.rotation.x,
            y: projectCurrent.rotation.y,
            z: projectCurrent.rotation.z,
          },
          {
            x: projectNew.rotation.x,
            y: projectNew.rotation.y,
            z: projectNew.rotation.z,
            ease: 'circ.out',
          }
        )

        TweenMax.fromTo(
          projectCurrentChildren.scale,
          3.8,
          {
            x: projectCurrentChildren.scale.x,
            y: projectCurrentChildren.scale.y,
            z: projectCurrentChildren.scale.z,
          },
          {
            x: projectNewChildren.scale.x,
            y: projectNewChildren.scale.y,
            z: projectNewChildren.scale.z,
            ease: 'circ.out',
          }
        )

        TweenMax.fromTo(
          projectCurrentChildren.position,
          3.8,
          {
            z: projectCurrentChildren.position.z,
          },
          { z: projectNewChildren.position.z, ease: 'circ.out' }
        )

        ReadingProject = false
      }
    }

    function _OpenProject(cube) {
      setSelectedProject(parseInt(cube.name))

      !ReadingProject && Signals.onProjectAnimationDone.dispatch('project')
      ReadingProject = true
      const tl = new TimelineMax({ delay: 1 })

      TweenMax.fromTo(
        cube.rotation,
        2.8,
        { x: cube.rotation.x },
        { x: 0, ease: 'circ.out' }
      )

      TweenMax.fromTo(
        cube.parent.rotation,
        2.8,
        {
          x: cube.parent.rotation.x,
          y: cube.parent.rotation.y,
          z: cube.parent.rotation.z,
        },
        { x: 0, y: 0, z: 0, ease: 'circ.out' }
      )

      TweenMax.fromTo(
        cube.scale,
        3.8,
        {
          x: cube.scale.x,
          y: cube.scale.y,
          z: cube.scale.z,
        },
        { x: 2.5, y: 3.5, z: 0.5, ease: 'circ.out' }
      )

      TweenMax.fromTo(
        cube.position,
        3.8,
        {
          z: cube.position.z,
        },
        { z: 0.2, ease: 'circ.out' }
      )
    }

    function _onTouchmove(event) {
      if (touch_navigation) {
        delta_navigation = currentTouchPosition - previousTouchPosition

        currentTouchPosition = event.targetTouches[0].clientX
      }
    }

    function _onTouchstart(event) {
      touch_navigation = true

      previousTouchPosition = event.targetTouches[0].clientX

      currentTouchPosition = event.targetTouches[0].clientX
    }

    function _onTouchend() {
      touch_navigation = false
      delta_navigation = 0
    }

    function _onMouseDown(event) {
      touch_navigation = true

      previousTouchPosition = event.clientX

      currentTouchPosition = event.clientX
    }

    function _onMouseUp() {
      touch_navigation = false
      setTimeout(() => {
        delta_navigation = 0
      }, 500)
    }

    function _onMousemove(e) {
      if (touch_navigation) {
        delta_navigation = currentTouchPosition - previousTouchPosition

        currentTouchPosition = e.clientX
      }

      let followMouseX = e.clientX / window.innerWidth / 80
      let followMouseY = e.clientY / window.innerHeight / 80

      const currentMouse = mouse
      TweenMax.fromTo(
        mouse,
        0,
        { x: currentMouse.x, y: currentMouse.y },
        { x: followMouseX, y: followMouseY, ease: 'circ.out' }
      )
    }

    function _onClick(event) {
      event.stopImmediatePropagation()
      const raycaster = new Raycaster()
      const RayMouse = new Vector2()
      RayMouse.x = (event.clientX / window.innerWidth) * 2 - 1
      RayMouse.y = -(event.clientY / window.innerHeight) * 2 + 1

      // update the picking ray with the camera and mouse position
      raycaster.setFromCamera(RayMouse, camera)

      // calculate objects intersecting the picking ray

      var intersects = raycaster.intersectObjects(scene.children, true)

      if (
        intersects &&
        intersects[0] &&
        intersects[0].object.name !== 'plane' &&
        intersects[0].object.enable === false &&
        delta_navigation === 0
      ) {
        openProject = intersects[0].object
        _OpenProject(openProject)
        openProject['enable'] = true
      }
    }

    function _onResize() {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
      composer.setSize(window.innerWidth, window.innerHeight)
    }

    _addEvents()

    function _addEvents() {
      window.addEventListener('touchstart', _onTouchstart)
      window.addEventListener('touchend', _onTouchend)
      window.addEventListener('touchmove', _onTouchmove)

      window.addEventListener('resize', _onResize)
      window.addEventListener('click', _onClick, false)
      window.addEventListener('mousemove', _onMousemove)
      window.addEventListener('mousedown', _onMouseDown)
      window.addEventListener('mouseup', _onMouseUp)
    }

    /**
    RAF
    */
    function animate() {
      delta += 0.1
      deltaRotation += 0.1

      if (!ReadingProject) {
        _updateItems()
      }

      const planeMesh = scene.getObjectByName('plane')

      if (get(planeMesh, 'material.userData')) {
        planeMesh.material.userData.u_time.value = delta + 1000
        planeMesh.material.userData.frequency.value = noise.frequency
        planeMesh.material.userData.speed.value = noise.speed
        planeMesh.material.userData.amplitude.value = noise.amplitude
        planeMesh.material.userData.depth.value = noise.depth
      }

      CubesProject.forEach((cube, index) => {
        const cubeMesh = scene.getObjectByName(cube)

        if (cubeMesh) {
          if (!cubeMesh.enable) {
            cubeMesh.rotation.x =
              (Math.PI / 2 + deltaRotation / 30) * (index + 1)
            cubeMesh.position.x =
              Math.cos(delta / 10) / (100 + Math.random() + 1)
            if (cubeMesh.rotation.x.toFixed(2) === Math.PI.toFixed(2)) {
              deltaRotation = 0
            }
          }
        }
      })

      projectsModels.position.x = -baseX

      camera.position.x = mouse.x
      camera.position.y = -mouse.y
      camera.position.z = CameraPosition.z
      backLight.position.x = LightPosition.x
      backLight.position.y = LightPosition.y
      backLight.position.z = LightPosition.z

      raf(animate)
      render()
    }

    /**
    Render loop
  */
    function render() {
      if (SETTINGS.useComposer) {
        composer.render()
      } else {
        renderer.clear()
        renderer.render(scene, camera)
      }
    }
  })

  return (
    <div id="application">
      <Header
        siteTitle={get(props, 'data.site.siteMetadata.title')}
        siteSubTitle={get(props, 'data.site.siteMetadata.subtitle')}
      />

      <PreloaderScene />
      <Project
        currentProject={get(
          props,
          `data.allContentfulBlogPost.edges[${selectedProject}].node`
        )}
      />
      <div id="MainScene"></div>
    </div>
  )
}

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 2000, maxHeight: 2000, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default MainScene
