import {
  MeshPhongMaterial,
  ShaderMaterial,
  UniformsUtils,
  ShaderChunk,
  UniformsLib
} from "three";
import beginVertex from "../assets/glsl/vertexShader.glsl";
import noise from "../assets/glsl/noise.glsl";
import move from "../assets/glsl/move.glsl";

const ShaderCustomMaterial = new MeshPhongMaterial({
  color: 0xccdcc5,
  specular: 0x002eff,
  emissive: 0x0,
  shininess: 0,
  reflectivity: 0
});

ShaderCustomMaterial.userData.u_time = { value: 0 };
ShaderCustomMaterial.userData.frequency = { value: 0.015 };
ShaderCustomMaterial.userData.speed = { value: 0.05 };
ShaderCustomMaterial.userData.amplitude = { value: 10 };
ShaderCustomMaterial.userData.depth = { value: 10 };

ShaderCustomMaterial.onBeforeCompile = shader => {
  shader.uniforms.u_time = ShaderCustomMaterial.userData.u_time;
  shader.uniforms.frequency = ShaderCustomMaterial.userData.frequency;
  shader.uniforms.speed = ShaderCustomMaterial.userData.speed;
  shader.uniforms.amplitude = ShaderCustomMaterial.userData.amplitude;
  shader.uniforms.depth = ShaderCustomMaterial.userData.depth;

  shader.vertexShader =
    "uniform float u_time;\n uniform float frequency;\n uniform float speed;\n uniform float amplitude;\n uniform float depth;\n" +
    shader.vertexShader;
  shader.vertexShader = shader.vertexShader.replace("#include <common>", noise);
  shader.vertexShader = shader.vertexShader.replace(
    "#include <begin_vertex>",
    beginVertex
  );
  shader.vertexShader = shader.vertexShader.replace(
    "#include <clipping_planes_vertex>",
    move
  );
};

// THREE.ExtendedMaterial = function(material, hooks) {
//   material.material.onBeforeCompile = function(shader) {
//     shader.uniforms.u_time = material.userData.u_time;

//     var vertexShader = shader.vertexShader;
//     var fragmentShader = shader.fragmentShader;
//     for (var name in hooks) {
//       vertexShader = vertexShader.replace("%" + name + "%", hooks[name]);
//       fragmentShader = fragmentShader.replace("%" + name + "%", hooks[name]);
//     }

//     shader.vertexShader = "uniform float u_time;\n" + shader.vertexShader;
//     shader.vertexShader = shader.vertexShader.replace(
//       "#include <common>",
//       noise
//     );
//     shader.vertexShader = shader.vertexShader.replace(
//       "#include <begin_vertex>",
//       beginVertex
//     );
//     shader.vertexShader = shader.vertexShader.replace(
//       "#include <clipping_planes_vertex>",
//       move
//     );
//     // shader.vertexShader = vertexShader;
//     shader.fragmentShader = fragmentShader;
//   };

//   material.needsUpdate = true;

//   return material;
// };

export default ShaderCustomMaterial;
