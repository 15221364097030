import { PlaneBufferGeometry, Mesh } from "three";
import ExtendedMaterial from "../libs/ShaderPhongMaterial";

function PlaneWave() {
  const material = ExtendedMaterial;
  material.uniforms = {
    u_time: { value: 0 },
    frequency: { value: 0.015 },
    speed: { value: 0.05 },
    amplitude: { value: 10 },
    depth: { value: 10 }
  };

  material.needsUpdate = true;

  const geometry = new PlaneBufferGeometry(20, 20, 230, 230);

  geometry.verticesNeedUpdate = true;
  geometry.elementsNeedUpdate = true;
  geometry.morphTargetsNeedUpdate = true;
  geometry.uvsNeedUpdate = true;
  geometry.normalsNeedUpdate = true;
  geometry.colorsNeedUpdate = true;
  geometry.tangentsNeedUpdate = true;

  const plane = new Mesh(geometry, material);
  plane.receiveShadow = true;
  plane.castShadow = true;
  plane.name = "plane";

  return plane;
}

export default PlaneWave;
