import React, { useEffect } from 'react'
import { TimelineMax, TweenMax } from 'gsap'
import get from 'lodash/get'
import '../assets/styles/app.scss'
import Signals from '../core/Signals'

function Header(props) {
  function animate() {
    console.log('animate')
    const tl = new TimelineMax({ paused: true })
    tl.fromTo('.header--text-title', 2, { opacity: 0 }, { opacity: 1 })
      .fromTo('.header--text-subtitle', 1, { opacity: 0 }, { opacity: 1 })
      .fromTo('.navigation--anchor', 1, { opacity: 0 }, { opacity: 1 })
      .play()
  }

  function animateDisappear() {
    const tl = new TimelineMax({ paused: true })
    tl.fromTo('.header', 0.3, { opacity: 1 }, { opacity: 0 }).play()
  }

  function animateAppear() {
    const tl = new TimelineMax({ paused: true })
    tl.fromTo('.header', 0.3, { opacity: 0 }, { opacity: 1 }).play()
  }

  useEffect(() => {
    Signals.onAssetsLoaded.add(animate)
    Signals.onProjectAnimationDone.add(animateDisappear)
    Signals.onProjectAnimationClose.add(animateAppear)
    return () => {
      Signals.onAssetsLoaded.remove()
      Signals.onProjectAnimationDone.remove()
      Signals.onProjectAnimationClose.remove()
    }
  }, [
    Signals.onAssetsLoaded,
    Signals.onProjectAnimationDone,
    Signals.onProjectAnimationClose,
  ])

  const { siteTitle, siteSubTitle } = props

  return (
    <header className="header">
      <div className="header--container-left">
        <h1 className="header--text-title" style={{ opacity: 0 }}>
          {siteTitle}
        </h1>
        <span className="header--text-subtitle" style={{ opacity: 0 }}>
          {siteSubTitle}
        </span>
      </div>

      <div className="header--container-right">
        <div className="navigation">
          <a
            style={{ opacity: 0 }}
            className="navigation--anchor navigation--anchor-left navigation--anchor-right"
            href="#about"
          >
            About me
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
