import React, { useEffect } from 'react'
import { TimelineMax, TweenMax } from 'gsap'
import '../assets/styles/app.scss'
import close from '../assets/images/close.svg'
import Signals from '../core/Signals'

function Project(props) {
  function animation() {
    const tl = new TimelineMax({ delay: 3, paused: true })
    tl.to('.project', 0.3, { display: 'block' }).to('.project', 0.3, {
      opacity: 1,
    })
    tl.play()
  }

  function reverseAnimation() {
    const tl = new TimelineMax({ delay: 0.2, paused: true })
    tl.to('.project', 0.2, { opacity: 0 }).to('.project', 0.2, {
      display: 'none',
    })
    tl.play()
  }

  useEffect(() => {
    console.log('props', props)

    Signals.onProjectAnimationDone.add(animation)
    Signals.onProjectAnimationClose.add(reverseAnimation)
    return () => {
      Signals.onProjectAnimationDone.remove()
      Signals.onProjectAnimationClose.remove()
    }
  }, [Signals.onProjectAnimationDone, Signals.onProjectAnimationClose])

  return (
    <div
      className="project"
      style={{
        display: 'none',
        opacity: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1000,
      }}
    >
      <div className="project-wrapper" style={{ padding: '0 5%' }}>
        <img
          src={close}
          className="project-close"
          onClick={() => Signals.onProjectAnimationClose.dispatch()}
        ></img>
        <h1 className="project-title">{props.currentProject.title}</h1>
        <p
          className="project-body"
          dangerouslySetInnerHTML={{
            __html: props.currentProject.description.childMarkdownRemark.html,
          }}
        ></p>
      </div>
    </div>
  )
}

export default Project
