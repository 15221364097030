const AllModels = function() {
  return {
    groups: {
      rotation: {
        x: 0,
        y: Math.PI / 1.4,
        z: -Math.PI / 1.8,
      },
      position: {
        x: 0,
        y: 0,
        z: 10,
      },
    },
    cube: {
      size: {
        x: 0.3,
        y: 0.1,
        z: 0.1,
      },
    },
  }
}

const LightPosition = function() {
  return {
    x: 15,
    y: 50,
    z: 38,
    intensity: 1,
    shadowRadius: 100,
    shadowCamera: 5,
    bias: 0,
  }
}

const CameraPosition = function() {
  return {
    x: -8,
    y: -21,
    z: 10.6,
  }
}

const noise = function() {
  return {
    frequency: 0.3,
    speed: 0.0045,
    amplitude: 1.9,
    depth: 3.1,
  }
}

exports.AllModels = AllModels()
exports.LightPosition = LightPosition()
exports.CameraPosition = CameraPosition()
exports.noise = noise()
