import React, { useEffect } from 'react'
import { TimelineMax, TweenMax } from 'gsap'
import '../assets/styles/app.scss'

function Preloader() {
  useEffect(() => {
    const tl = new TimelineMax({
      delay: 2,
      paused: true,
      onComplete: function() {
        console.log('SendMessage')
        Signals.onAssetsLoaded.dispatch('loaded')
      },
    })

    const currentCounter = { val: 0 },
      maxCounter = 100

    tl.to(currentCounter, 1, {
      val: maxCounter,
      roundProps: 'val',
      onUpdate: function() {
        document.body.querySelector('#progress--text').innerHTML =
          currentCounter.val
        document.body.querySelector(
          '#progress'
        ).style.width = `${currentCounter.val}%`
      },
    })
      .to('#loader', 1, { opacity: 0 })
      .to('#loader', 0, {
        display: 'none',
      })
      .play()
    return () => {}
  }, [])

  return (
    <div id="loader" className="loader">
      <div className="progressbar">
        <div id="progress" className="progressbar--progress"></div>
        <div id="progress--text" className="progressbar--files-current">
          0
        </div>
        <div className="progressbar--files-max">100%</div>
      </div>
    </div>
  )
}

export default Preloader
